import React from "react";
import RotateLoader from "react-spinners/RotateLoader";
import ReactJson from "react-json-view";
import services from "../services";
import styles from "../styles";
import { GenericDatePicker } from "./GenericDatePicker";
import subDays from "date-fns/subDays";
const zlib = require("zlib");

export default class Tools extends React.Component {
	constructor() {
		super();
		this.state = {
			responseData: null,
			isLoading: false,
			isCreate: false,
			selectedGenOption: "jv-dist",
			selectedGenOperationOption: "sync-legacy-provider-practice-facility",
			startDate: subDays(new Date(), 7),
			endDate: new Date(),
			allData: false,
		};
	}

	handleCheckboxChange = () => {
		this.setState({
			allData: !this.state.allData,
		});
	};

	handleGenDateChange = (dateRange) => {
		let { start, end } = dateRange;
		this.setState({ startDate: start });
		this.setState({ endDate: end });
	};

	componentWillMount() {}

	componentDidMount() {}

	gzipDecompress = async (compressed) => {
		return await new Promise((resolve) => {
			console.log(compressed);
			zlib.gunzip(Buffer.from(compressed, "base64"), (error, result) => {
				if (error != null) {
					alert("Could not decompress Buffer");
					throw error;
				}
				if (result != null) {
					resolve(JSON.parse(result.toString()));
				}
			});
		});
	};

	decompressData = async () => {
		if (this.state.textArea == null) {
			return;
		}
		let decompressedData = null;
		try {
			decompressedData = await this.gzipDecompress(this.state.textArea);
		} catch (e) {
			console.log(e);
			return;
		}
		this.setState({ responseData: decompressedData });
	};

	renderResultJsonDisplay = () => {
		return (
			<div className="is-margin-top">
				<h2 className="title">Decompressed Json:</h2>
				<div
					style={{ backgroundColor: "white", width: "100%", height: "100%" }}
				>
					<ReactJson
						src={this.state.responseData}
						theme="summerfruit:inverted"
						collapsed={1}
					/>
				</div>
			</div>
		);
	};

	submitTask = async () => {
		// TODO: default set email to logged in user?
		if (this.state.email == null || !this.state.email.includes("@")) {
			alert("Please enter a valid email address!");
			return;
		}
		// TODO: submit task with email information so that it can
		// run distribution generate file to temp bucket and send it
		let tableDist = undefined;
		if (this.state.selectedGenOption === "provider-dist") {
			tableDist = "provider";
		} else if (this.state.selectedGenOption === "facility-dist") {
			tableDist = "facility";
		}
		await services.api.fargateDistributionTask(
			this.state.email,
			tableDist,
			undefined,
			this.state.startDate,
			this.state.endDate,
			this.state.allData
		);
		alert("SUCCESS WAIT ~12-40 Minutes For Email");
	};

	submitOperationalTask = async () => {
		// require email for this as well so we know who did it
		if (this.state.email == null || !this.state.email.includes("@")) {
			alert("Please enter a valid email address!");
			return;
		}
		let scriptName = undefined;
		if (this.state.selectedGenOperationOption) {
			scriptName = this.state.selectedGenOperationOption;
		} else {
			alert("failed option select.");
			return;
		}
		await services.api.fargateDistributionTask(
			this.state.email,
			undefined,
			scriptName,
			undefined,
			undefined,
			undefined
		);
		alert("SUCCESS DANGEROUS HOPE YOU KNOW WHAT YOU ARE DOING!");
	};

	renderSelect = () => {
		return (
			<div
				className="select is-primary"
				style={{ marginTop: "10px" }}
				onChange={(option) => {
					this.setState({ selectedGenOption: option.target.value });
				}}
			>
				<select>
					<option value="jv-dist">Javelina Distribution</option>
					<option value="provider-dist"> Provider Table Distribution</option>
					<option value="facility-dist"> Facility Table Distribution</option>
				</select>
			</div>
		);
	};

	renderOptionalSelect = () => {
		return (
			<div
				className="select is-primary"
				style={{ marginTop: "10px" }}
				onChange={(option) => {
					this.setState({ selectedGenOperationOption: option.target.value });
				}}
			>
				<select>
					<option value="sync-legacy-provider-practice-facility">
						Sync Legacy Provider, Practice and Facility
					</option>
					<option value="sync-legacy-provider">Sync Legacy Provider</option>
					<option value="sync-legacy-practice">Sync Legacy Practice</option>
					<option value="sync-legacy-facility">Sync Legacy Facility</option>
					<option value="cache-dist">Regenerate Location_Cache Table</option>
				</select>
			</div>
		);
	};

	render() {
		return (
			<div>
				<RotateLoader
					css={styles.override}
					size={30}
					margin={30}
					color={"#ED008B"}
					loading={this.state.isLoading}
				/>
				<div id="topDivider" className="container is-margin-top">
					<h2 className="title">Generators:</h2>
					<div className="control is-full-content is-centered-content has-icons-left">
						<input
							className={`input is-full-width is-primary2-border`}
							onChange={(text) => this.setState({ email: text.target.value })}
							value={this.state.email}
							type="text"
							placeholder="Email To Receive File"
						/>
						<span className="icon is-small is-left">
							<i className="fas fa-envelope-square"></i>
						</span>
					</div>
					<div className="is-flex is-flex-direction-row">
						{this.renderSelect()}
						<div
							className="is-flex is-flex-direction-row"
							style={{
								margin: "5px",
								marginTop: "10px",
							}}
						>
							<GenericDatePicker
								dateFrom={this.state.startDate}
								dateTo={this.state.endDate}
								onChange={this.handleGenDateChange}
								disabled={this.state.allData}
								startLabel=""
								endLabel=""
							/>
							<label>
								<input
									type="checkbox"
									checked={this.state.allData}
									onChange={this.handleCheckboxChange}
								/>
								<b>&nbsp;All Data</b>
							</label>
						</div>
					</div>
					<div
						className="tile is-parent is-align-center"
						onClick={() => this.submitTask()}
					>
						<article className="tile is-child box article-button has-white-text">
							<p className="subtitle">Generate File Distribution</p>
						</article>
					</div>
				</div>

				<div id="topDivider" className="container is-margin-top">
					<div className="is-flex is-flex-direction-row">
						{this.renderOptionalSelect()}
						<div
							className="is-flex is-flex-direction-row"
							style={{
								margin: "5px",
								marginTop: "10px",
							}}
						></div>
					</div>
					<div
						className="tile is-parent is-align-center"
						onClick={() => this.submitOperationalTask()}
					>
						<article className="tile is-child box article-button has-white-text">
							<p className="subtitle">Submit Operational Task</p>
						</article>
					</div>
				</div>

				<div id="topDivider" className="container is-margin-top">
					<h2 className="title">Decompress Data:</h2>
					<textarea
						className="textarea is-primary2-border"
						placeholder="compressed data"
						value={this.state.textArea}
						onChange={(text) => {
							this.setState({ textArea: text.target.value });
						}}
					/>
					<button
						className="button is-primary2-button is-fullwidth is-medium is-margin-top"
						onClick={() => {
							this.decompressData();
						}}
					>
						Decompress
					</button>
					{this.state.responseData && this.renderResultJsonDisplay()}
				</div>
			</div>
		);
	}
}
